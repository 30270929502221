import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { addDays, addMonths, format } from 'date-fns';
import { Moment } from 'moment';
import * as moment from 'moment';
import { CentroService } from 'app/main/_services/centro.service';
import { AsesorService } from 'app/main/_services/asesor.service';
import { TipoNovedadService } from 'app/main/_services/tipo-novedad.service';
import { TipoPredioService } from 'app/main/_services/tipo-predio.service';
import { URL_API_LOCAL } from 'app/main/_config/constants';
import { AuthService } from 'app/main/auth/auth.service';
import { Centro } from 'app/main/_models/centro';
import { element } from '@angular/core/src/render3';


@Injectable({
  providedIn: 'root'
})
export class ListaService implements Resolve<any>
{

  fechaIni: Moment;
  routeQueryParams: any;
  onListChanged: BehaviorSubject<any>;

  /**
   * 
   * @param {HttpClient} _httpClient 
   * @param {CentroService} _centroService 
   * @param {AsesorService} _asesorService 
   * @param {TipoNovedadService} _tipoNovedadService 
   * @param {TipoPredioService} _tipoPredioService 
   * @param {AuthService} _authService
   */
  constructor(
    private _httpClient: HttpClient,
    private _centroService: CentroService,
    private _asesorService: AsesorService,
    private _tipoNovedadService: TipoNovedadService,
    private _tipoPredioService: TipoPredioService,
    private _authService: AuthService
  ) {
    this.onListChanged = new BehaviorSubject({});

    this.fechaIni = moment(addMonths(new Date(), -1).valueOf());
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    this.routeQueryParams = route.queryParams;

    return new Promise((resolve, reject) => {

      Promise.all([
        this._getVisitas(
          (this.routeQueryParams['pageIndex'] ? parseInt(this.routeQueryParams['pageIndex'], 10) + 1 : 1),
          this.routeQueryParams['pageSize'],
          this.routeQueryParams['centro'],
          this.routeQueryParams['asesor'],
          this.routeQueryParams['tipoNovedad'],
          this.routeQueryParams['tipoPredio'],
          this.routeQueryParams['codigoUsu'],
          this.routeQueryParams['fechaIni'] ? this.routeQueryParams['fechaIni'] : this.fechaIni,
          this.routeQueryParams['fechaFin'])

      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * 
   * @param page 
   * @param limit 
   * @param centro 
   * @param asesor 
   * @param tipoNovedad 
   * @param tipoPredio 
   * @param fechaIni 
   * @param fechaFin 
   * @returns {Promise<any>}
   */
  private _getVisitas(page?: number, limit?: number,
    centro?: string, asesor?: string,
    tipoNovedad?: string, tipoPredio?: string, codigoUsu?: number, 
    fechaIni?: number, fechaFin?: number): Promise<any> {
    const paramsPage = page ? `&page=${page}` : '';
    const paramsLimit = limit ? `&limit=${limit}` : '';
    const paramsCentro = centro ? `&centro=${centro}` : '';
    const paramsAsesor = asesor ? `&asesor=${asesor}` : '';
    const paramsTipoNovedad = tipoNovedad ? `&nov=${tipoNovedad}` : '';
    const paramsTipoPredio = tipoPredio ? `&TPredio=${tipoPredio}` : '';
    const paramCodUsu = codigoUsu ? `&codigoUsu=${codigoUsu}` : '';
    const paramsfechaIni = fechaIni ? `&fechaini=${this._format(fechaIni)}` : '';
    const paramsfechaFin = fechaFin ? `&fechafin=${this._format(fechaFin)}` : '';
    const url = URL_API_LOCAL + 'visita?abc=1'
      + paramsPage
      + paramsLimit
      + paramsCentro
      + paramsAsesor
      + paramsTipoNovedad
      + paramsTipoPredio
      + paramCodUsu
      + paramsfechaIni
      + paramsfechaFin;

    return new Promise((resolve, reject) => {
      this._httpClient.get(url)
        .subscribe((response: any) => {
          this.onListChanged.next(response);
          resolve(response);
        }, reject);
    });
  }

  /**
   * 
   * @returns {Promise<any>}
   */
  getCentros(): Promise<any> {
    return new Promise((resolve, reject) => {
      Promise.all([]).then(
        () => {
          resolve(this._authService.centros);
        }
      )
    });
  }

  /**
   * 
   * @returns {Promise<any>}
   */
  getTipoNovedades(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._tipoNovedadService.getTipoNovedades(1, 1000)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * 
   * @returns {Promise<any>}
   */
  getTipoPredios(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._tipoPredioService.getTipoPredios(1, 1000)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }

  /**
   * 
   * @param {string} codigo
   * @returns {Promise<any>}
   */
  getAsesores(codigo: string): Promise<any> {

    if (this._authService.asesores.length > 0) {
      return new Promise((resolve, reject) => {
        Promise.all([]).then(
          () => {
            const asesores = [];
            this._authService.asesores.forEach(element => {
              if (element.centro != null && element.centro.codigo === codigo) {
                asesores.push(element);
              }
            });
            resolve(asesores);
          }
        );
      });
    }


    return new Promise((resolve, reject) => {
      this._asesorService.getAsesoresByCentro(codigo)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });


  }

  private _format(input: number | string): string {
    const fecha = new Date(parseInt(`${input}`, 10));
    return format(fecha, 'YYYY-M-D');
  }
}
