import { Injectable } from '@angular/core';
import { URL_API_LOCAL } from '../_config/constants';
import { Parametros } from '../_models/IParametros';
import { Utilidades } from '../_models/utiliidades';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PqrService {

  constructor(private http: HttpClient) { }

  public getSiasPresupuestos(parametros: Parametros, numeroPqr: number): Observable<any> {
    console.log(parametros);
    let url = URL_API_LOCAL + 'Notificaciones/Sias?Abc=1';
    url += parametros.Pagina ? '&Page=' + parametros.Pagina : '';
    url += parametros.Limite ? `&Limit=${parametros.Limite}` : '';
    url += parametros.IdCentro ? `&IdCentro=${parametros.IdCentro}` : '';
    url += parametros.IdMunicipio ? `&IdMunicipio=${parametros.IdMunicipio}` : '';
    url += parametros.Asesor ? `&Asesor=${parametros.Asesor}` : '';
    url += parametros.FechaIni ? `&FechaIni=${Utilidades.getFechaString(parametros.FechaIni)}` : '';
    url += parametros.FechaFin ? `&FechaFin=${Utilidades.getFechaString(parametros.FechaIni)}` : '';
    url += numeroPqr ? `&NumeroPqr=${numeroPqr}` : '';
    console.log(url);
    return this.http.get(url);
  }

  public getHistorialPqrs(codigoUsuario: number): Observable<any[]> {
    const url = URL_API_LOCAL + `HistorialPqr/Historial?CodigoUsuario=${codigoUsuario}`;
    return this.http.get<any[]>(url).pipe(
      map(
        (resp) => {
          const historialPqr: any[] = [];
          for (const pqr of resp['data']) {
            if (pqr.detalle.length > 0) {
              const causal = pqr.detalle[0];
              const registro = {
                numero: pqr.numeroPqr,
                fecha: pqr.fecha,
                categoria: causal.categoria,
                clase: causal.clase,
                causal: causal.causal,
                obsCausal: causal.observacion,
                solucion: null,
                fechaSol: null,
                obsSolucion: null
              };
              if (causal.solucion.length > 0) {
                const solucion = causal.solucion[0];
                registro.solucion = solucion.solucion;
                registro.fechaSol = solucion.fecha;
                registro.obsSolucion = solucion.observacion;
              }
              historialPqr.push(registro);
            }
          }
          return historialPqr;
        }
      )
    );
  }
}
