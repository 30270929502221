import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_API_LOCAL } from 'app/main/_config/constants';
import { IRespuestaQuery } from 'app/main/_models/respuestas';

@Injectable({
  providedIn: 'root'
})
export class NovedadConstruccionesService {

  constructor(
    private _httpClient: HttpClient
  ) { }


  getConstrucciones(codigo: string): Promise<IRespuestaQuery> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<IRespuestaQuery>(`${URL_API_LOCAL}visita/novedades/construccion/${codigo}`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  
}
