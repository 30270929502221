import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_API_LOCAL } from '../_config/constants';
import { Observable } from 'rxjs';
import { ISuscriptor } from '../_models/interface';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
    
  constructor(private http: HttpClient) { }


  getDatosCliente(idCliente: number, codUsuario?: number, nombre?: string  ): Observable<ISuscriptor>{
    let url = URL_API_LOCAL + `DatosUsuarios/InformacionCliente?IdUsuario=${idCliente}`;
    url += codUsuario ? '&CodUsuario=' + codUsuario : '';
    url += nombre ? '&Nombre=' + nombre : '';
    return this.http.get<ISuscriptor>(url);
  }

  getDatosUsuario(codUsuario: number): Observable<any>{
    let url = URL_API_LOCAL + `DatosUsuarios/InformacionUsuario?CodUsuario=${codUsuario}`;
    return this.http.get<any>(url);
  }

    
}
